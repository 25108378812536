import React from "react"

import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="error text-center">
      <div className="error__imgWrapper">
        <img
          className="error__img"
          src={require("../images/404.png")}
          alt="Can't find the page"
        />
      </div>
      <h1 className="error__heading">Sorry We Can`t Find That Page!</h1>
      <p className="error__subheading">
        The page you are looking for was moved, removed, renamed or never
        existed.
      </p>
    </section>

    <div class="global-footer container">
      <Footer />
    </div>
  </Layout>
)

export default NotFoundPage
